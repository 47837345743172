document.addEventListener("DOMContentLoaded", function () {
  const expandableElements = document.querySelectorAll(".group");

  expandableElements.forEach((element) => {
    const textNode = element.querySelector(".text-overflow");
    // const originalText = textNode.textContent;

    element.addEventListener("mouseenter", function () {
      // textNode.textContent = originalText; // Show the full text
      element.classList.add("overflow-visible");
    });

    element.addEventListener("mouseleave", function () {
      // textNode.textContent = originalText.slice(0, 50) + "..."; // Truncate text
      element.classList.remove("overflow-visible");
    });
  });
});
